.squad-div {
  position: relative;
  text-align: left;
  align-items: flex-start;
}

.squad-image{
    margin-top: 20px;
    margin-left: 338px;
    position: absolute;
}
h2 {
  padding-top: 30px;
  margin-left: 338px;
}

