
.request-p {
    text-align:left;
}

.request-header {
    color: white;
    font-family: "Teko";
    font-size: 40px;
}

.request-col {
    align-items: left;
    justify-content: center;
    width: 100px;
}

.test {
    align-items: left;
}

.second-row {
    padding-top: 50px;
}

.request-h3 {
    margin-left: 400px;
}

.request-h2 {
    margin-left: 405px;
}
