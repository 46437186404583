body {
  margin: 0;
  font-family: -apple-system
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}

@font-face {
    font-family: 'Teko';
    src: local('Teko'), url(./Fonts/Teko/Teko-Medium.ttf);
}
