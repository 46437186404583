.svg-carousel {
  margin-top: 80px;
  text-align: left;
  height: 500px;
  width: 1000px;
  overflow-x: auto;
  margin-left: 250px;
}

h1 {
  color: white;
  visibility: hidden;
}

.carousel-caption {
  color: #fff;
  top: 50%;
}

h2 {
  color: white;
  font-size: 45px !important;
  font-family: "Teko", sans-serif;
}
h3 { color: white;
  font-family: "Teko", sans-serif;
}

h4 { color: white;
  font-family: "Teko", sans-serif;
}
.standard-text {
  color: white;
  font-size: 20px !important;
  white-space: pre-line;
  font-family: "Teko";
  font-weight: bold !important;
  padding-top: 20px;
  text-align: justify;
}

.standard-list {
  color: white;
  font-size: 20px !important;
  white-space: pre-line;
  font-family: "Teko";
  font-weight: bold !important;
  text-decoration: underline;
  display: inline-block;
  padding-right: 532px;
}

.svg-popover {
  background-color: #112056 !important;
  color: white !important;
}

.surrounding-div {
  padding-bottom: 50px;
}

.popover-content {
  color: white !important;
  text-align: justify;
}

.popover-title {
  background-color: #112056 !important;
  color: white !important;
}

button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-size: 1rem;
  overflow: hidden;
  background-color: #112056;
  font-family: "Teko";
  font-weight: bold !important;
  height: 200px;
  width: 400px;
  border-width: 4px;
  border-color: white;
  border-style: solid;
}

.svg-popover {
  width: 900px !important;
  max-width: 600px !important;
}

.popover-div {
  display: inline-block;
}

img {
  margin-left: 100px;
  vertical-align: top;
  float: right;
}


.news-container {
    margin-top: 80px;


}

.header-news {
    font-size: 30px;
    padding-bottom: 100px;
    padding-left: 150px;
    margin-top: 150px;

}
.next-games {
    margin-top: 40px;
}

.header-games {
    margin-left: 0px;
}

.match-logo {
 height: 40px;
 border-radius: 50%;
 margin-left: 10px;
 margin-right: 10px;
}

.mactch-col {
    align-items: center;
    justify-content: center;
}
