.svg-table {
    width: 830px;
    border: 12px solid #1550b0;
    outline: 8px solid #112056;
    padding: 20px 20px;
    background: white;
}

.svg-matchday {

    width: 830px;
    border: 12px solid #1550b0;
    outline: 8px solid #112056;
    padding: 20px 20px;
    background: white;
}

.team-container {
float: left;
width: 800px;
margin-left: 100px;
}
.svg-matchday-col {
    width: 400px;
    margin-right: 200px;
}

.ama-embed__branding {
    display: none;
    
}

.svg-first-container {
    margin-top: 100px;
    width: 1600px;
}

.team-header {
    margin-left: 0px;
    padding-bottom: 40px;
}

.test-container {
    margin: auto;
    width: 100%;
    max-height: 900px;
overflow-x: auto;
    margin-top: 100px;

}
