.footer-middle {
  height: 200px;
  background-color: #1550b0;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
}

.header-text {
  color: white;
  font-family: "Teko";
  font-size: "12px";
  padding-top: 9px;
}

.img-col {
  align-items: center;
  justify-content: center;
}

.facebook-image-footer {
  height: 50px;
  padding-top: 7px;
  margin-left: 60px;
  margin-top: 20px;
}

.fußball-image-footer {
  height: 50px;
  padding-top: 8px;
  margin-left: 60px;
  margin-top: 20px;
}

.header-text {
  color: white;
  font-family: "Teko";
  font-size: "12px";
  padding-top: 9px;
}

.swfv-image-footer {
  height: 50px;
  padding-top: 7px;
  margin-left: 60px;
  margin-top: 20px;
}

.whatsapp-image-footer {
  height: 50px;
  padding-top: 8px;
  margin-left: 60px;
  margin-top: 20px;
}

.justify-content-md-center {
  position: absolute;
  bottom: 20px;
  width: 1000px;
}

.logo {
  margin-bottom: 50px;
  margin-right: 35px;
}
