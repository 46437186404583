.svg-header {
  background-color: #1550b0;
  height: 40px;
}

.facebook-image {
  height: 30px;
  padding-top: 7px;
  margin-left: 15px;
}

.telephone-image {
  height: 27px;
  padding-top: 12px;
  margin-right: 10px;
  width: 15px;
}

.fußball-image {
  height: 30px;
  padding-top: 8px;
  margin-left: 15px;
}

.dfb-image {
  height: 30px;
  padding-top: 8px;
  margin-left: 15px;
  margin-right: 30px;
}

.header-text {
  color: white;
  font-family: "Teko";
  font-size: "12px";
  padding-top: 9px;
  letter-spacing: 0.1em;
}

.swfv-image {
  height: 30px;
  padding-top: 7px;
  margin-left: 15px;
}

.whatsapp-image {
  height: 30px;
  padding-top: 8px;
  margin-left: 15px;
}
