.footer-bottom {
  height: 100px;
  max-width: 100%;
  background-color: #112056;
  align-items: right;
  text-align: right;
}

.copyright {
    padding-top: 30px;
    padding-left: 55px;
    margin-right: 10px;
}
