.footer-top {
  height: 400px;
  max-width: 100%;
  background-color: #112056 !important;
  opacity: 1;
}

.logo-bottom {
  position: absolute;
  bottom: -145px;
  padding-top: 40px;
}

.sitemapHeader {
  color: white;
  font-weight: bold;
  font-size: 30px;
  font-family: Teko;
  margin-top: 20px;
  float: none;
}

.sitemapContent {
  color: white;
  font-family: Teko;
  margin-top: 25px;
  font-size: 20px;
  text-align: center;
}

.address-div {
  text-align: left;
}

.sitemap-row {
  text-align: center;
}

.sitemap-label {
  font-weight: bold;
}

a.sitemapLink {
  color: white;
}
