.month-details {
    color: white;
    font-family: "Teko";
    font-size: 22px;
}

.events-row {
    margin-top: 50px;
}

.events-container {
    margin-top: 100px;
}

.events-header {
    margin-right: 260px;
}

.month-header {
    font-weight: bold;
}
