.svg-nav {
  height: 100px;
  color: black;
  text-emphasis: bold;
  background-color: #112056;
}

.navbar-brand {
  position: flex;
  align-items: center;
}

.navbar-brand > img {
  padding-top: 150px;
  margin-left: 75px;

  height: 370px;
  width: auto;
}

.nav-drop-item {
  font-family: "Teko" !important;
  color: white !important;
  background-color: #1550b0 !important;
  font-size: 20px !important;
}

.dropdown-toggle {
  color: white;
  font-family: "Teko";
  font-weight: bold;
  font-size: 20px;
}
.nav-link {
  color: white;
  font-family: "Teko";
  font-weight: bold;
  font-size: 22px;
}

.nav-link-single:hover {
  text-decoration: none;
  border-bottom: 10px solid white;
  color: white;
}

.nav-drop-item:hover {
  background-color: #112056 !important;
}
