.management-header {
    margin-left: 0px;
}

.management-descr {
    color: white;
    font-family: "Teko";
    font-size: 20px;
    margin-bottom: 50px;
}

.management-details {
    color: white;
    font-family: "Teko";
    font-size: 24px;
    line-height: 120%;
    margin-top: 40px;
}

.management-div {
    border: 2px solid;
    border-color: white;
    margin-top: 15px;
}

.management-col {
    align-items: center;
    align: center;
    padding-right: 50px;
}

.management-img {
    margin: 0 auto;
    width: 200px;
    margin-right: 74px;
}
