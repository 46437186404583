.privacy {
  text-align: left;
  margin-top: 50px;
  max-height: 950px;
  overflow-x: auto;
  scrollbar-color: white;
}

.privacy-paragraph {
  color: white;
  font-family: "Teko";
  font-size: 22px;
}

.privacy-link {
  color: white;
  text-decoration: underline;
}
